import request, { RequestConfig } from "@/apis/index";
import { ResOp, ResByPageVo, TicketApiUserVerifyPageVo, DeepRequired } from "../../interface";

export interface GetApiUserVerifyPageResult extends ResOp {
    data: GetApiUserVerifyPageResultData;
}

export interface GetApiUserVerifyPageResultData extends ResByPageVo {
    records: TicketApiUserVerifyPageVo[];
}

/**
 * 分页
 * /api/user/verify/page
 */
export function getApiUserVerifyPage(params: GetApiUserVerifyPageParams, config?: RequestConfig) {
    const paramsInput = {
        id: params.id,
        createTime: params.createTime,
        updateTime: params.updateTime,
        goodsId: params.goodsId,
        itemId: params.itemId,
        userId: params.userId,
        sort: params.sort,
        size: params.size,
        page: params.page,
        startTime: params.startTime,
        endTime: params.endTime,
    };
    return request.get<DeepRequired<GetApiUserVerifyPageResult>>(`/api/user/verify/page`, {
        params: paramsInput,
        ...(config as any)
    });
}

interface GetApiUserVerifyPageParams {
    /** 唯一id(主键) */
    id?: number;
    /** 创建时间 */
    createTime?: string;
    /** 更新时间 */
    updateTime?: string;
    /** 核销场次id */
    goodsId?: string;
    /** 核销票档id */
    itemId?: string;
    /** 用户id */
    userId?: string;
    /** 排序 */
    sort?: string;
    /** 分页查询每页数量 */
    size?: number;
    /** 分页查询当前页数 */
    page?: number;
    /** 查询时间访问，默认查询 createTime */
    startTime?: string;
    /** 查询时间访问，默认查询 createTime */
    endTime?: string;
}
