import request, { RequestConfig } from "@/apis/index";
import { ResOp, ResByPageVo, TicketUserEntity, DeepRequired } from "../../interface";

export interface GetApiUserOrderPageResult extends ResOp {
    data: GetApiUserOrderPageResultData;
}

export interface GetApiUserOrderPageResultData extends ResByPageVo {
    records: TicketUserEntity[];
}

/**
 * /api/user/order/page
 */
export function getApiUserOrderPage(params: GetApiUserOrderPageParams, config?: RequestConfig) {
    const paramsInput = {
        status: params.status,
        sort: params.sort,
        size: params.size,
        page: params.page,
    };
    return request.get<DeepRequired<GetApiUserOrderPageResult>>(`/api/user/order/page`, {
        params: paramsInput,
        ...(config as any)
    });
}

interface GetApiUserOrderPageParams {
    /** 订单状态=>  -2:未付款的订单 -1：用户取消 1:待核销 2:核销完成 3退款成功 */
    status?: string;
    /** 排序 */
    sort?: string;
    /** 分页查询每页数量 */
    size?: number;
    /** 分页查询当前页数 */
    page?: number;
}
