import request, { RequestConfig } from "@/apis/index";
import { ResOp, ResByPageVo, TicketApiMessagePageVo, DeepRequired } from "../../interface";

export interface GetApiUserMessagePageResult extends ResOp {
    data: GetApiUserMessagePageResultData;
}

export interface GetApiUserMessagePageResultData extends ResByPageVo {
    records: TicketApiMessagePageVo[];
}

/**
 * 分页
 * /api/user/message/page
 */
export function getApiUserMessagePage(params: GetApiUserMessagePageParams, config?: RequestConfig) {
    const paramsInput = {
        id: params.id,
        createTime: params.createTime,
        updateTime: params.updateTime,
        userId: params.userId,
        title: params.title,
        content: params.content,
        read: params.read,
        type: params.type,
        ...params.extra,
        taskId: params.taskId,
        extraType: params.extraType,
        sort: params.sort,
        size: params.size,
        page: params.page,
        startTime: params.startTime,
        endTime: params.endTime,
    };
    return request.get<DeepRequired<GetApiUserMessagePageResult>>(`/api/user/message/page`, {
        params: paramsInput,
        ...(config as any)
    });
}

interface GetApiUserMessagePageParams {
    /** 唯一id(主键) */
    id?: number;
    /** 创建时间 */
    createTime?: string;
    /** 更新时间 */
    updateTime?: string;
    /** 用户id */
    userId?: string;
    /** 消息标题 */
    title?: string;
    /** 消息内容 */
    content?: string;
    /** 是否已读 */
    read?: boolean;
    /** 消息类型 */
    type?: string;
    /** 额外拓展参数 */
    extra?: any;
    /** 系统推送创建的任务id */
    taskId?: number;
    /** 超链接跳转等 */
    extraType?: string;
    /** 排序 */
    sort?: string;
    /** 分页查询每页数量 */
    size?: number;
    /** 分页查询当前页数 */
    page?: number;
    /** 查询时间访问，默认查询 createTime */
    startTime?: string;
    /** 查询时间访问，默认查询 createTime */
    endTime?: string;
}
