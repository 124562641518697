import request, { RequestConfig } from "@/apis/index";
import { DeepRequired, TicketApiUserViewerDeleteDto } from "../../interface";

/**
 * 删除
 * /api/user/viewer/delete
 */
export function postApiUserViewerDelete(input: TicketApiUserViewerDeleteDto, config?: RequestConfig) {
    return request.post<DeepRequired<any>>(`/api/user/viewer/delete`, input, config as any);
}
