import request, { RequestConfig } from "@/apis/index";
import { DeepRequired } from "../../interface";

/**
 * /api/user/verify/record/verify
 */
export function postApiUserVerifyRecordVerify(input: PostApiUserVerifyRecordVerifyInput, config?: RequestConfig) {
    return request.post<DeepRequired<any>>(`/api/user/verify/record/verify`, input, config as any);
}

export interface PostApiUserVerifyRecordVerifyInput {
    recordId?: string;
}
