import request, { RequestConfig } from "@/apis/index";
import { ResOp, TicketUserPerson, DeepRequired } from "../../interface";

export interface GetApiOpenUserPersonResult extends ResOp {
    data: TicketUserPerson;
}

/**
 * /api/open/user/person
 */
export function getApiOpenUserPerson(config?: RequestConfig) {
    return request.get<DeepRequired<GetApiOpenUserPersonResult>>(`/api/open/user/person`, config as any);
}
