import request, { RequestConfig } from "@/apis/index";
import { ResOp, GetHomeVo, DeepRequired } from "../../interface";

export interface GetApiOpenHomeResult extends ResOp {
    data: GetHomeVo;
}

/**
 * /api/open/home
 */
export function getApiOpenHome(config?: RequestConfig) {
    return request.get<DeepRequired<GetApiOpenHomeResult>>(`/api/open/home`, config as any);
}
