import request, { RequestConfig } from "@/apis/index";
import { ResOp, GoodsItemDetailVo, DeepRequired } from "../../interface";

export interface PostApiLogoutResult extends ResOp {
    data: GoodsItemDetailVo;
}

/**
 * /api/logout
 */
export function postApiLogout(config?: RequestConfig) {
    return request.post<DeepRequired<PostApiLogoutResult>>(`/api/logout`, config as any);
}
