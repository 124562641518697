import request, { RequestConfig } from "@/apis/index";
import { ResOp, UserViewerVo, DeepRequired } from "../../interface";

export interface GetApiUserViewerResult extends ResOp {
    data: UserViewerVo[];
}

/**
 * /api/user/viewer
 */
export function getApiUserViewer(config?: RequestConfig) {
    return request.get<DeepRequired<GetApiUserViewerResult>>(`/api/user/viewer`, config as any);
}
