import request, { RequestConfig } from "@/apis/index";
import { ResOp, TicketApiUserViewerUpdateVo, DeepRequired, TicketApiUserViewerUpdateDto } from "../../interface";

export interface PostApiUserViewerUpdateResult extends ResOp {
    data: TicketApiUserViewerUpdateVo;
}

/**
 * 更新
 * /api/user/viewer/update
 */
export function postApiUserViewerUpdate(input: TicketApiUserViewerUpdateDto, config?: RequestConfig) {
    return request.post<DeepRequired<PostApiUserViewerUpdateResult>>(`/api/user/viewer/update`, input, config as any);
}
