import { useReactive } from 'ahooks';
import { FC, useEffect } from 'react';
import env from './common/env';
import Landing from './Landing';
import Loading from './Loading';
import { HashRouter } from './router';
import isPARS from './rpf/un/isPARS';
import './styles/global.scss';

import { postApiOpenJssdk } from './apis/controller';
import getQuery from './rpf/un/getQuery';
import { setShare } from './utils/wechat';
import useUser from './store/user';

const App: FC = () => {
  const loading = useReactive({
    complete: false,
    onComplete() {
      this.complete = true;
    }
  });
  const userStore = useUser();

  useEffect(() => {
    postApiOpenJssdk({
      url: window.location.href.replace(/#{1}.*/, '')
    }).then(([isError, res]) => {
      if (isError) {
        return;
      }
      window['wx']?.config({
        debug: getQuery()?.debug === '1',
        appId: res.data.appId,
        timestamp: res.data.timestamp,
        nonceStr: res.data.nonceStr,
        signature: res.data.signature,
        jsApiList: [
          'scanQRCode',
          'updateTimelineShareData',
          'updateAppMessageShareData',
          'onMenuShareTimeline',
          'onMenuShareAppMessage'
        ]
      });
      // 获取配置
      // window['wx']?.ready(() => {
      //   setTimeout(() => {
      //     setShare(userStore?.config?.wechatShareConfig!);
      //   }, 200);
      // });
    });
  }, []);

  if (!loading.complete) {
    return (
      <Loading
        onComplete={() => {
          loading.onComplete();
        }}
      />
    );
  }

  // if (!env.dev && !isPARS()) {
  //   return <Landing />;
  // }

  return <HashRouter />;
};

export default App;
