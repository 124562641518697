import request, { RequestConfig } from "@/apis/index";
import { DeepRequired } from "../../interface";

/**
 * 验证码
 * /oauth/captcha
 */
export function getOauthCaptcha(params: GetOauthCaptchaParams, config?: RequestConfig) {
    const paramsInput = {
        height: params.height,
        width: params.width,
        type: params.type,
    };
    return request.get<DeepRequired<any>>(`/oauth/captcha`, {
        params: paramsInput,
        ...(config as any)
    });
}

interface GetOauthCaptchaParams {
    height?: number;
    width?: number;
    type?: string;
}
