import request, { RequestConfig } from "@/apis/index";
import { ResOp, Object, DeepRequired } from "../../interface";

export interface GetApiUserCancelOrderByOrderNoResult extends ResOp {
    data: Object;
}

/**
 * /api/user/cancel/order/{orderNo}
 */
export function getApiUserCancelOrderByOrderNo(params: GetApiUserCancelOrderByOrderNoParams, config?: RequestConfig) {
    return request.get<DeepRequired<GetApiUserCancelOrderByOrderNoResult>>(`/api/user/cancel/order/${params.orderNo}`, config as any);
}

interface GetApiUserCancelOrderByOrderNoParams {
    orderNo: string;
}
