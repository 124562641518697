import request, { RequestConfig } from "@/apis/index";
import { ResOp, DeepRequired } from "../../interface";

export interface PostApiOpenWxAuthorizeUrlResult extends ResOp {
    data: any;
}

/**
 * /api/open/wx/authorizeUrl
 */
export function postApiOpenWxAuthorizeUrl(input: PostApiOpenWxAuthorizeUrlInput, config?: RequestConfig) {
    return request.post<DeepRequired<PostApiOpenWxAuthorizeUrlResult>>(`/api/open/wx/authorizeUrl`, input, config as any);
}

export interface PostApiOpenWxAuthorizeUrlInput {
    url?: string;
}
