import request, { RequestConfig } from "@/apis/index";
import { ResOp, DeepRequired } from "../../interface";

export interface PostApiPayByTypeResult extends ResOp {
    data: any;
}

/**
 * /api/pay/{type}
 */
export function postApiPayByType(params: PostApiPayByTypeParams, input: PostApiPayByTypeInput, config?: RequestConfig) {
    return request.post<DeepRequired<PostApiPayByTypeResult>>(`/api/pay/${params.type}`, input, config as any);
}

interface PostApiPayByTypeParams {
    type: string;
}

export interface PostApiPayByTypeInput {
    /** 微信openId */
    openId?: string;
    /** 订单号 */
    orderNo?: string;
}
