import request, { RequestConfig } from "@/apis/index";
import { ResOp, TicketUserEntity, DeepRequired } from "../../interface";

export interface GetApiUserInfoResult extends ResOp {
    data: TicketUserEntity;
}

/**
 * /api/user/info
 */
export function getApiUserInfo(config?: RequestConfig) {
    return request.get<DeepRequired<GetApiUserInfoResult>>(`/api/user/info`, config as any);
}
