import { postApiLogout } from '@/apis/controller';
import { router } from '@/router';
import { Toast } from '@xyz/cat-design';

export async function logout() {
  const [isError] = await postApiLogout({ showErrorTips: true });
  if (!isError) {
    Toast.success('退出成功');

    router.navigate(-1);
  }
}
