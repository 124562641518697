import request, { RequestConfig } from "@/apis/index";
import { ResOp, TicketApiUserViewerListVo, DeepRequired } from "../../interface";

export interface GetApiUserViewerListResult extends ResOp {
    data: TicketApiUserViewerListVo[];
}

/**
 * 列表
 * /api/user/viewer/list
 */
export function getApiUserViewerList(params: GetApiUserViewerListParams, config?: RequestConfig) {
    const paramsInput = {
        id: params.id,
        createTime: params.createTime,
        updateTime: params.updateTime,
        cardCode: params.cardCode,
        username: params.username,
        mobile: params.mobile,
        email: params.email,
        userId: params.userId,
        deleted: params.deleted,
        sort: params.sort,
        size: params.size,
        startTime: params.startTime,
        endTime: params.endTime,
    };
    return request.get<DeepRequired<GetApiUserViewerListResult>>(`/api/user/viewer/list`, {
        params: paramsInput,
        ...(config as any)
    });
}

interface GetApiUserViewerListParams {
    /** 唯一id(主键) */
    id?: number;
    /** 创建时间 */
    createTime?: string;
    /** 更新时间 */
    updateTime?: string;
    /** 身份证 */
    cardCode?: string;
    /** 姓名 */
    username?: string;
    /** 手机号码 */
    mobile?: string;
    /** 邮箱 */
    email?: string;
    /** 用户账号 */
    userId?: string;
    /** 逻辑删除标识，未删除为 0，已删除为删除时间 */
    deleted?: number;
    /** 排序 */
    sort?: string;
    /** 分页查询每页数量 */
    size?: number;
    /** 查询时间访问，默认查询 createTime */
    startTime?: string;
    /** 查询时间访问，默认查询 createTime */
    endTime?: string;
}
