import request, { RequestConfig } from "@/apis/index";
import { ResOp, GetGoodsDetailVo, DeepRequired } from "../../interface";

export interface GetApiOpenGoodsDetailByIdResult extends ResOp {
    data: GetGoodsDetailVo;
}

/**
 * /api/open/goods/detail/{id}
 */
export function getApiOpenGoodsDetailById(params: GetApiOpenGoodsDetailByIdParams, config?: RequestConfig) {
    return request.get<DeepRequired<GetApiOpenGoodsDetailByIdResult>>(`/api/open/goods/detail/${params.id}`, config as any);
}

interface GetApiOpenGoodsDetailByIdParams {
    id: string;
}
