import request, { RequestConfig } from "@/apis/index";
import { ResOp, OssFileVo, DeepRequired } from "../../interface";

export interface GetApiOssTokenResult extends ResOp {
    data: OssFileVo;
}

/**
 * /api/oss/token
 */
export function getApiOssToken(config?: RequestConfig) {
    return request.get<DeepRequired<GetApiOssTokenResult>>(`/api/oss/token`, config as any);
}
