import request, { RequestConfig } from "@/apis/index";
import { ResOp, Object, DeepRequired, TicketApiMobileUpdateDto } from "../../interface";

export interface PostApiUserMobileUpdateResult extends ResOp {
    data: Object;
}

/**
 * /api/user/mobile/update
 */
export function postApiUserMobileUpdate(input: TicketApiMobileUpdateDto, config?: RequestConfig) {
    return request.post<DeepRequired<PostApiUserMobileUpdateResult>>(`/api/user/mobile/update`, input, config as any);
}
