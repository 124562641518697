import request, { RequestConfig } from "@/apis/index";
import { ResOp, TicketApiUserVerifyRecordInfoVo, DeepRequired } from "../../interface";

export interface GetApiUserVerifyRecordInfoResult extends ResOp {
    data: TicketApiUserVerifyRecordInfoVo;
}

/**
 * 详情
 * /api/user/verify/record/info
 */
export function getApiUserVerifyRecordInfo(params: GetApiUserVerifyRecordInfoParams, config?: RequestConfig) {
    const paramsInput = {
        id: params.id,
    };
    return request.get<DeepRequired<GetApiUserVerifyRecordInfoResult>>(`/api/user/verify/record/info`, {
        params: paramsInput,
        ...(config as any)
    });
}

interface GetApiUserVerifyRecordInfoParams {
    id: string;
}
