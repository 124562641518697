import request, { RequestConfig } from "@/apis/index";
import { ResOp, TicketApiUserUpdateVo, DeepRequired, TicketApiUpdateDto } from "../../interface";

export interface PostApiUserUpdateResult extends ResOp {
    data: TicketApiUserUpdateVo;
}

/**
 * 更新
 * /api/user/update
 */
export function postApiUserUpdate(input: TicketApiUpdateDto, config?: RequestConfig) {
    return request.post<DeepRequired<PostApiUserUpdateResult>>(`/api/user/update`, input, config as any);
}
