import request, { RequestConfig } from "@/apis/index";
import { ResOp, TicketApiUserVerifyRecordCreateVo, DeepRequired, TicketApiUserVerifyRecordCreateDto } from "../../interface";

export interface PostApiUserVerifyRecordCreateResult extends ResOp {
    data: TicketApiUserVerifyRecordCreateVo;
}

/**
 * 新建
 * /api/user/verify/record/create
 */
export function postApiUserVerifyRecordCreate(input: TicketApiUserVerifyRecordCreateDto, config?: RequestConfig) {
    return request.post<DeepRequired<PostApiUserVerifyRecordCreateResult>>(`/api/user/verify/record/create`, input, config as any);
}
