import request, { RequestConfig } from "@/apis/index";
import { ResOp, JssdkSignatureVo, DeepRequired } from "../../interface";

export interface PostApiOpenJssdkResult extends ResOp {
    data: JssdkSignatureVo;
}

/**
 * /api/open/jssdk
 */
export function postApiOpenJssdk(input: PostApiOpenJssdkInput, config?: RequestConfig) {
    return request.post<DeepRequired<PostApiOpenJssdkResult>>(`/api/open/jssdk`, input, config as any);
}

export interface PostApiOpenJssdkInput {
    url?: string;
}
