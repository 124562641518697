import request, { RequestConfig } from "@/apis/index";
import { ResOp, GetGoodsItemDetailVo, DeepRequired } from "../../interface";

export interface GetApiGoodsItemDetailByIdResult extends ResOp {
    data: GetGoodsItemDetailVo;
}

/**
 * /api/goods/item/detail/{id}
 */
export function getApiGoodsItemDetailById(params: GetApiGoodsItemDetailByIdParams, config?: RequestConfig) {
    return request.get<DeepRequired<GetApiGoodsItemDetailByIdResult>>(`/api/goods/item/detail/${params.id}`, config as any);
}

interface GetApiGoodsItemDetailByIdParams {
    id: string;
}
