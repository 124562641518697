import request, { RequestConfig } from "@/apis/index";
import { ResOp, DeepRequired } from "../../interface";

export interface PostApiOpenWxGetOpenIdByCodeResult extends ResOp {
    data: any;
}

/**
 * /api/open/wx/getOpenIdByCode
 */
export function postApiOpenWxGetOpenIdByCode(input: PostApiOpenWxGetOpenIdByCodeInput, config?: RequestConfig) {
    return request.post<DeepRequired<PostApiOpenWxGetOpenIdByCodeResult>>(`/api/open/wx/getOpenIdByCode`, input, config as any);
}

export interface PostApiOpenWxGetOpenIdByCodeInput {
    code?: string;
}
