import ReactDOM from 'react-dom';
import App from './App';
import createWatermark from './rpf/un/createWatermark';
import isPARS from './rpf/un/isPARS';
import isWeChat from './rpf/un/isWeChat';
import loadSentry from './rpf/un/loadSentry';
import paAuthorize from './rpf/un/paAuthorize';
import './rpf/react/vconsole';
import '@xyz/cat-design/es/package/Styles/index';
import '@xyz/cat-design/es/global/index';
import { postApiOpenWxAuthorizeUrl, postApiOpenWxGetOpenIdByCode } from './apis/controller';
// import getQuery from './rpf/un/getQuery';
import { get, set } from './utils/cacheKV';
import getQuery from './rpf/un/getQuery';
import filterQuery from './rpf/un/filterQuery';

// document.querySelector('body')?.addEventListener(
//   'touchmove',
//   function (e) {
//     const parent: any = getScrollParent(e.target! as any);
//     console.log(parent);
//     if (parent === window || parent?.scrollHeight === parent?.clientHeight) e.preventDefault();
//   },
//   {
//     passive: false
//   }
// );
const documentHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
};
window.addEventListener('resize', documentHeight);
documentHeight();
createWatermark();

(async () => {
  await Promise.all([
    loadSentry(window.$TZ_CONFIG?.conf.sentry?.dsn ?? '').catch(() => {
      console.warn('sentry 加载失败');
    })
  ]);

  if (isWeChat() && !get('openId5')) {
    // 获取用户的openid
    if (getQuery().code) {
      const [isError, ret] = await postApiOpenWxGetOpenIdByCode({ code: getQuery().code });
      if (isError) {
        console.warn('获取用户openid失败');
        alert(JSON.stringify({ ret, code: getQuery().code }, null, 2));
        return;
      } else {
        const openId = ret.data;
        set('openId5', openId);
        // return;
      }
      // 没有code的时候，需要重定向
    } else {
      const [isError, ret] = await postApiOpenWxAuthorizeUrl({
        url: filterQuery(window.location.href, ['code'], {}, '')
      });
      if (isError) {
        console.warn('获取微信授权地址失败');
      } else if (ret) {
        // window.location.href = ret.data;
        window.location.replace(ret.data);
      }
    }
  }

  ReactDOM.render(<App />, document.getElementById('root'));
})();
