import request, { RequestConfig } from "@/apis/index";
import { ResOp, DeepRequired, AddUserViewDto } from "../../interface";

export interface PostApiUserViewerResult extends ResOp {
    data: any;
}

/**
 * /api/user/viewer
 */
export function postApiUserViewer(input: AddUserViewDto, config?: RequestConfig) {
    return request.post<DeepRequired<PostApiUserViewerResult>>(`/api/user/viewer`, input, config as any);
}
