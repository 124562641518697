import request, { RequestConfig } from "@/apis/index";
import { ResOp, ResByPageVo, TicketWalletPageItemVo, DeepRequired } from "../../interface";

export interface GetApiUserWalletPageResult extends ResOp {
    data: GetApiUserWalletPageResultData;
}

export interface GetApiUserWalletPageResultData extends ResByPageVo {
    records: TicketWalletPageItemVo[];
}

/**
 * /api/user/wallet/page
 */
export function getApiUserWalletPage(params: GetApiUserWalletPageParams, config?: RequestConfig) {
    const paramsInput = {
        status: params.status,
        sort: params.sort,
        size: params.size,
        page: params.page,
    };
    return request.get<DeepRequired<GetApiUserWalletPageResult>>(`/api/user/wallet/page`, {
        params: paramsInput,
        ...(config as any)
    });
}

interface GetApiUserWalletPageParams {
    /** 状态码: 1待核销 2冻结 3已核销 4已转增 */
    status?: string;
    /** 排序 */
    sort?: string;
    /** 分页查询每页数量 */
    size?: number;
    /** 分页查询当前页数 */
    page?: number;
}
