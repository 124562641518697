import request, { RequestConfig } from "@/apis/index";
import { ResOp, UserLoginResVo, DeepRequired, LoginDto } from "../../interface";

export interface PostApiOpenLoginResult extends ResOp {
    data: UserLoginResVo;
}

/**
 * /api/open/login
 */
export function postApiOpenLogin(input: LoginDto, config?: RequestConfig) {
    return request.post<DeepRequired<PostApiOpenLoginResult>>(`/api/open/login`, input, config as any);
}
