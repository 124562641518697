import request, { RequestConfig } from "@/apis/index";
import { ResOp, TicketUserConfig, DeepRequired } from "../../interface";

export interface GetApiOpenUserConfigResult extends ResOp {
    data: TicketUserConfig;
}

/**
 * /api/open/user/config
 */
export function getApiOpenUserConfig(config?: RequestConfig) {
    return request.get<DeepRequired<GetApiOpenUserConfigResult>>(`/api/open/user/config`, config as any);
}
