import request, { RequestConfig } from "@/apis/index";
import { ResOp, GetSmsCodeVo, DeepRequired, GetSmsCodeDto } from "../../interface";

export interface PostApiOpenGetSmsCodeResult extends ResOp {
    data: GetSmsCodeVo;
}

/**
 * /api/open/get/sms/code
 */
export function postApiOpenGetSmsCode(input: GetSmsCodeDto, config?: RequestConfig) {
    return request.post<DeepRequired<PostApiOpenGetSmsCodeResult>>(`/api/open/get/sms/code`, input, config as any);
}
