import request, { RequestConfig } from "@/apis/index";
import { ResOp, DeepRequired } from "../../interface";

export interface GetApiOpenUserCityResult extends ResOp {
    data: string[];
}

/**
 * /api/open/user/city
 */
export function getApiOpenUserCity(config?: RequestConfig) {
    return request.get<DeepRequired<GetApiOpenUserCityResult>>(`/api/open/user/city`, config as any);
}
