import request, { RequestConfig } from "@/apis/index";
import { DeepRequired } from "../../interface";

/**
 * 校验验证码
 * /oauth/check/captcha
 */
export function getOauthCheckCaptcha(params: GetOauthCheckCaptchaParams, config?: RequestConfig) {
    const paramsInput = {
        captchaId: params.captchaId,
        value: params.value,
    };
    return request.get<DeepRequired<any>>(`/oauth/check/captcha`, {
        params: paramsInput,
        ...(config as any)
    });
}

interface GetOauthCheckCaptchaParams {
    captchaId?: string;
    value?: string;
}
