import request, { RequestConfig } from "@/apis/index";
import { ResOp, TicketOrderDetailVo, DeepRequired } from "../../interface";

export interface GetApiOrderByIdResult extends ResOp {
    data: TicketOrderDetailVo;
}

/**
 * /api/order/{id}
 */
export function getApiOrderById(params: GetApiOrderByIdParams, config?: RequestConfig) {
    return request.get<DeepRequired<GetApiOrderByIdResult>>(`/api/order/${params.id}`, config as any);
}

interface GetApiOrderByIdParams {
    id: number;
}
