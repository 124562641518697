import request, { RequestConfig } from "@/apis/index";
import { ResOp, TicketWalletItemVo, DeepRequired } from "../../interface";

export interface GetApiUserWalletInfoResult extends ResOp {
    data: TicketWalletItemVo;
}

/**
 * /api/user/wallet/info
 */
export function getApiUserWalletInfo(params: GetApiUserWalletInfoParams, config?: RequestConfig) {
    const paramsInput = {
        id: params.id,
    };
    return request.get<DeepRequired<GetApiUserWalletInfoResult>>(`/api/user/wallet/info`, {
        params: paramsInput,
        ...(config as any)
    });
}

interface GetApiUserWalletInfoParams {
    id?: number;
}
