import request, { RequestConfig } from "@/apis/index";
import { ResOp, ResByPageVo, GetGoodsPageVo, DeepRequired } from "../../interface";

export interface GetApiOpenGoodsPageResult extends ResOp {
    data: GetApiOpenGoodsPageResultData;
}

export interface GetApiOpenGoodsPageResultData extends ResByPageVo {
    records: GetGoodsPageVo[];
}

/**
 * /api/open/goods/page
 */
export function getApiOpenGoodsPage(params: GetApiOpenGoodsPageParams, config?: RequestConfig) {
    const paramsInput = {
        showType: params.showType,
        sort: params.sort,
        size: params.size,
        page: params.page,
        city: params.city,
    };
    return request.get<DeepRequired<GetApiOpenGoodsPageResult>>(`/api/open/goods/page`, {
        params: paramsInput,
        ...(config as any)
    });
}

interface GetApiOpenGoodsPageParams {
    /** 演出类型 */
    showType?: string;
    /** 排序 */
    sort?: string;
    /** 分页查询每页数量 */
    size?: number;
    /** 分页查询当前页数 */
    page?: number;
    city?: string;
}
