import request, { RequestConfig } from "@/apis/index";
import { ResOp, TicketCreateOrderVo, DeepRequired, TicketCreateOrderDto } from "../../interface";

export interface PostApiCreateOrderResult extends ResOp {
    data: TicketCreateOrderVo;
}

/**
 * /api/create/order
 */
export function postApiCreateOrder(input: TicketCreateOrderDto, config?: RequestConfig) {
    return request.post<DeepRequired<PostApiCreateOrderResult>>(`/api/create/order`, input, config as any);
}
