import request, { RequestConfig } from "@/apis/index";
import { ResOp, TicketApiMessageUpdateVo, DeepRequired, TicketApiMessageUpdateDto } from "../../interface";

export interface PostApiUserMessageUpdateResult extends ResOp {
    data: TicketApiMessageUpdateVo;
}

/**
 * 更新
 * /api/user/message/update
 */
export function postApiUserMessageUpdate(input: TicketApiMessageUpdateDto, config?: RequestConfig) {
    return request.post<DeepRequired<PostApiUserMessageUpdateResult>>(`/api/user/message/update`, input, config as any);
}
